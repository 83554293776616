* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
  

@font-face {
    font-family: demo;
    src: url(/src/assets/fonts/GROBOLD.ttf);
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    cursor: default;
    font-weight: 400;
}

a {
    text-decoration: none;
    cursor: pointer;
}

li {
    list-style: none;
}

.btn {
    width: 17.5vw;
    display: flex;
    font-weight: 200;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.btn img {
    width: 100%;
}

.btn li {
    list-style: none;
    font-family: demo;
    letter-spacing: .1vw;
    font-size: 1.2vw;
    line-height: 2.5vw;
    color: #412512;
    /* -webkit-text-stroke: 2px #E73511;
    -webkit-text-fill-color: #FFCD0F; */
    -webkit-text-stroke: 1px #000000;
    transition: all .3s;
    margin-top: -4.6vw;
    opacity: 1;
}

.btn1 li {
    color: #BFFF3A;
}

.btn:hover li {
    margin-top: -4.8vw;
    color: #cc00ff;
    text-shadow: 0 .15vw 0 #000000;
    filter: drop-shadow(0.1vw .2vw 0px #f1f1f1);
}

body{
    background: #112818;
}

/* -------------------------------------------Navbar Management------------------
-------------------- */

.header {
    width: 100%;
}

.header .navbar {
    border-radius: 2vw;
    width: 100%;
    height: auto;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header .navbar .left-side-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5vw;
}

.header .navbar a {
    width: 5vw;
    transition: all .3s;
}

.header .navbar a:hover {
    transform: translateY(-.5vw);
}

.header .navbar a img {
    width: 100%;
}

.centr-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5vw;
}

.header .centr-menu a:nth-child(1) {
    animation: bounceInDown 1s;
}

.header .centr-menu a:nth-child(2) {
    animation: bounceInDown 1.5s;
}

.header .centr-menu a:nth-child(3) {
    animation: bounceInDown 2s;
}

/* --------------------------------hero-section--------------
-------------------- */

.hero-section {
    width: 100%;
    height: 67vw;
    padding: 3vw 5vw;
    background-image: url(/src/assets/images/hero_bg.gif);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-section .heading {
    padding-top: 2vw;
    width: 40%;
}

.hero-section .container2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-section .container2 img {
    width: 40%;
}

.hero-section .container2 .top {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -18vw;
}

.select2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    padding: 1.3vw 0;
    background: #FFF097;
    overflow: hidden;
    border-radius: .5vw;
}

.contractaddress {
    font-size: 0.8vw !important;
    font-family: demo !important;
    text-align: center;
}

.linked {
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    outline:0;
    box-shadow:none;
    border:0!important;
    background: #FFF097;
    background-image: none;
    flex: 1;
    color:#412512;
    cursor:pointer;
    font-size: 1.2vw !important;
    font-family: demo !important;
    text-align: center;
	text-decoration:none !important;
 }
 
 select {
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    outline:0;
    box-shadow:none;
    border:0!important;
    background: #FFF097;
    background-image: none;
    flex: 1;
    color:#412512;
    cursor:pointer;
    font-size: 1.2vw !important;
    font-family: demo !important;
    text-align: center;
 }
 select::-ms-expand {
    display: none;
 }

.hero-section .bottom {
    width: 30%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.hero-section .bottom .lock {
    width: 1.5vw;
    position: absolute;
    top: 3.5vw;
    left: .5vw;
}

 /* Basic styling for the input element */
#myInput {
    margin-top: 2vw;
    width: 100%;
    padding: 1.2vw 3vw;
    padding-right: 10vw;
    border: none !important;
    outline: none !important;
    border-radius: 5px;
    font-size: 1.2vw;
    font-family: demo;
    background: #FFF097;
    line-height: 2vw;
  }
  
  /* Styling when the input element is focused */
  /* #myInput:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  } */
  

.hero-section .bottom .select2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 1vw 0;
    background: #FAE882;
    overflow: hidden;
    border-radius: 1vw;
    align-self: flex-end;
    margin-right: 1vw;
    margin-top: -3.8vw;
}

.hero-section .bottom select {
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    outline:0;
    box-shadow:none;
    border:0!important;
    background: #FAE882;
    background-image: none;
    flex: 1;
    color:#412512;
    cursor:pointer;
    font-size: 1.2vw;
    font-family: demo;
    text-align: center;
    padding-left: 2vw;
 }
.hero-section .bottom select::-ms-expand {
    display: none;
 }

 .hero-section .bottom .p {
    width: 2.5vw;
    align-self: flex-end;
    margin-right: 6.2vw;
    margin-top: -2.9vw;
    z-index: 2;
 }

 .hero-section .bottom p {
    color: #9A6E00;
    font-size: 1.2vw;
    line-height: 2vw;
    margin-top: 2vw;
    font-family: demo;
 }

.hero-section .btn {
    margin-top: 2vw;
}

.hero-section .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*------------------------- footer --------------------
-----------------------*/

footer {
    width: 100%;
    padding-top: 14vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

footer p {
    font-family: demo;
    color: #412512;
    font-size: 1.2vw;
}

footer .links {
    display: flex;
    align-items: center;
}

footer .links a {
    font-size: 1.2vw;
    color: #412512;
    font-family: demo;
}

footer .links a:hover {
    color: #9A6E00;
}

footer .links a:first-child {
    padding-right: 1vw;
    border-right: 2px solid #412512;
}

footer .links a:last-child {
    padding-left: 1vw;
}





/* --------------------------------website Responsive--------------
-------------------- */

/* ----------------------------@ 1280px--------
------- */

@media (max-width: 1280px) {



}

/* ----------------------------@ 980px--------
------- */

@media (max-width: 980px) {

}

/* ----------------------------@ 600px--------
------- */

@media (max-width: 600px) {
    
    .btn {
        width: 35vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    
    .btn img {
        width: 100%;
    }
    
    .btn li {
        list-style: none;
        font-family: demo;
        letter-spacing: .1vw;
        font-size: 2.5vw;
        line-height: 6.5vw;
        transition: all .3s;
        margin-top: -10vw;
        opacity: 1;
    }
    
    
    .btn:hover li {
        margin-top: -10.5vw;
        color: #cc00ff;
        text-shadow: 0 .3vw 0 #000000;
        filter: drop-shadow(0.1vw .6vw 0px #f1f1f1);
    }
    
    /* -------------------------------------------Navbar Management------------------
    -------------------- */
    
    .header .navbar a {
        width: 6vw;
    }

    .header .navbar .left-side-menu {
        padding-top: 5vw;
        padding-left: 2vw;
        flex-direction: column;
    }

    .header .navbar .centr-menu {
        position: relative;
        top: -2vw;
        padding-right: 3vw;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .header .navbar a {
        width: 10vw;
    }

    .header .navbar a img {
        width: 100%;
    }
    
    /* --------------------------------hero-section--------------
    -------------------- */
    
    .hero-section {
        width: 100%;
        height: 200vw;
        background-image: url(/src/assets/images/mob.gif);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: scroll;
    }
    

    .hero-section .heading {
        margin-top: -14vw;
        padding-top: 0vw;
        width: 60%;
    }
    
    .hero-section .container2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .hero-section .container2 img {
        width: 70%;
    }
    
    .hero-section .container2 .top {
        width: 55%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -33vw;
    }
    
    .select2 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
        padding: 2vw 0;
        background: #FFF097;
        overflow: hidden;
        border-radius: 1vw;
    }
    
    select {
        -webkit-appearance:none;
        -moz-appearance:none;
        -ms-appearance:none;
        appearance:none;
        outline:0;
        box-shadow:none;
        border:0!important;
        background: #FFF097;
        background-image: none;
        flex: 1;
        color:#412512;
        cursor:pointer;
        font-size: 3vw !important;
        font-family: demo !important;
        text-align: center;
     }
     select::-ms-expand {
        display: none;
     }
    
    .hero-section .bottom {
        width: 55%;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    .hero-section .bottom .lock {
        width: 3vw;
        position: absolute;
        top: 6.5vw;
        left: 1vw;
    }
    
     /* Basic styling for the input element */
    #myInput {
        margin-top: 4vw;
        width: 100%;
        padding: 2.5vw 5vw;
        padding-right: 20vw;
        border: none !important;
        outline: none !important;
        border-radius: 1vw;
        font-size: 3vw;
        font-family: demo;
        background: #FFF097;
        line-height: 2vw;
      }
      
      /* Styling when the input element is focused */
      /* #myInput:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      } */
      
    
    .hero-section .bottom .select2 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
        padding: 1.5vw 0;
        background: #FAE882;
        overflow: hidden;
        border-radius: 2vw;
        align-self: flex-end;
        margin-right: 2vw;
        margin-top: -7.5vw;
    }
    
    .hero-section .bottom select {
        -webkit-appearance:none;
        -moz-appearance:none;
        -ms-appearance:none;
        appearance:none;
        outline:0;
        box-shadow:none;
        border:0!important;
        background: #FAE882;
        background-image: none;
        flex: 1;
        color:#412512;
        cursor:pointer;
        font-size: 3vw;
        font-family: demo;
        text-align: center;
        padding-left: 2vw;
     }
    .hero-section .bottom select::-ms-expand {
        display: none;
     }
    
     .hero-section .bottom .p {
        width: 3.5vw;
        align-self: flex-end;
        margin-right: 14vw;
        margin-top: -5vw;
        z-index: 2;
     }
    
     .hero-section .bottom p {
        color: #9A6E00;
        font-size: 3vw;
        line-height: 5vw;
        margin-top: 5vw;
        font-family: demo;
     }
    
    .hero-section .btn {
        margin-top: 2vw;
    }
    
    .hero-section .buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    /*------------------------- footer --------------------
    -----------------------*/
    
    footer {
        width: 100%;
        padding-top: 95vw;
        display: flex;
        flex-direction: column;
        gap: 3vw;
        align-items: center;
        justify-content: space-between;
    }
    
    footer p {
        font-family: demo;
        color: #412512;
        font-size: 3vw;
    }
    
    footer .links {
        display: flex;
        align-items: center;
    }
    
    footer .links a {
        font-size: 3vw;
        color: #412512;
        font-family: demo;
    }
    
    footer .links a:hover {
        color: #9A6E00;
    }
    
    footer .links a:first-child {
        padding-right: 1vw;
        border-right: 2px solid #412512;
    }
    
    footer .links a:last-child {
        padding-left: 1vw;
    }
}